import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../axios';
import InputMask from 'react-input-mask';

import './basket.scss';
import { Nav, Footer, ProductMin } from "../../components";
import { selectIsAuth } from "../../redux/slices/auth";
import Modal from "../../components/ConfirmationModal"; // Імпортуємо модальне вікно

export const Basket = () => {
    const navigate = useNavigate();
    const isAuth = useSelector(selectIsAuth);
    const { posts } = useSelector(state => state.post);
    const [products, setProducts] = useState(null);
    const [price, setPrice] = useState(0);
    const [deleted, setDeleted] = useState(false);
    const [showModal, setShowModal] = useState(false); // Додано для показу модального вікна

    // Нові стани для форми
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        city: '',
        department: '',
        comment: ''
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const data = JSON.parse(window.localStorage.getItem('basket'));
        if (data && data.length > 0) { 
            setProducts(data);
            let count = 0;
            data.forEach(product => {
                count += parseInt(product.price);
            });
            setPrice(count);
        } else {
            setProducts(null);
            setPrice(0);
        }
    }, [deleted]);

    const deleteProduct = (id) => {
        const basket = JSON.parse(window.localStorage.getItem('basket'));
        const postId = basket.findIndex((elem) => elem.product === id);
        basket.splice(postId, 1);
        window.localStorage.setItem('basket', JSON.stringify(basket));

        if (basket.length === 0) {
            setProducts(null); 
            setPrice(0);
        } else {
            setProducts([...basket]);
            let count = 0;
            basket.forEach(product => {
                count += parseInt(product.price);
            });
            setPrice(count);
        }

        setDeleted(!deleted);
    }

    const Order = async () => {
        // Перевірка на заповненість полів
        if (!formData.firstName || !formData.lastName || !formData.phone || !formData.city || !formData.department) {
            alert("Будь ласка, заповніть всі обов'язкові поля!");
            return;
        }
    
        // Перевірка на те, чи номер телефону повністю заповнений
        if (formData.phone.includes('_')) {
            alert("Будь ласка, введіть коректний номер телефону!");
            return;
        }
    
        const order = {
            products: products.map(({ product, size }) => {
                const dataProduct = posts.data.find((elem) => elem._id === product);
                
                if (!dataProduct) {
                    console.error(`Product with ID ${product} not found.`);
                    return null;
                }
    
                return {
                    product: dataProduct._id,
                    size,
                    count: 1,
                    slug: dataProduct.slug,
                    article: dataProduct.article,
                    title: dataProduct.title,
                    price: dataProduct.price,
                    new_price: dataProduct.new_price,
                    images: dataProduct.images
                };
            }).filter(item => item !== null),
            customerData: formData
        };
    
        if (order.products.length === 0) {
            alert("Не вдалося створити замовлення: жоден продукт не знайдено.");
            return;
        }
    
        try {
            await axios.post('/order', order);
            setProducts(null);
            window.localStorage.removeItem('basket');
            setFormData({
                firstName: '',
                lastName: '',
                phone: '',
                city: '',
                department: '',
                comment: ''
            });
            setShowModal(true); // Показати модальне вікно після успішного замовлення
        } catch (error) {
            console.error("Error creating order:", error);
        }
    }
    
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <>
            <Nav />
            <header className="basket">
                <div className="container">
                    <h3 className="basket__title title2">Корзина</h3>

                    {products && posts.data && <div className="basket__wrapper">
                        <div className="basket__products">
                            {products.map(({ product, size }, index) => {
                                const dataProduct = posts.data.find((elem) => elem._id === product);
                                return <div key={index} className="basket__product">
                                    <ProductMin data={dataProduct} size={size} counter={true} price={price} setPrice={setPrice} products={products} setProducts={setProducts} />
                                    <button className="basket__delete" onClick={() => deleteProduct(dataProduct._id)}>
                                        <svg fill="#bbb" width="20" height="20" viewBox="0 0 485 485" xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                                <g>
                                                    <rect x="67.224" width="350.535" height="71.81" />
                                                    <path d="M417.776,92.829H67.237V485h350.537V92.829H417.776z M165.402,431.447h-28.362V146.383h28.362V431.447z M256.689,431.447 h-28.363V146.383h28.363V431.447z M347.97,431.447h-28.361V146.383h28.361V431.447z" />
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                            })}
                        </div>

                        <div className="basket__sum">
                            <div className="basket__cost">
                                <p>Вартість товарів:</p>
                                <p>{price} грн</p>
                            </div>
                            <div className="basket__payable">
                                <p>До сплати: </p>
                                <p>{price} грн</p>
                            </div>
                            <hr />
                            
                            {/* Форма для введення даних клієнта */}
                            <div className="basket__form">
                                <label>
                                    Телефон:
                                    <InputMask
                                        mask="+38 (999) 999-99-99"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        maskChar="_"
                                    >
                                        {(inputProps) => <input type="text" {...inputProps} name="phone" placeholder="+38 (___) ___-__-__" required />}
                                    </InputMask>
                                </label>
                                <label>
                                    Ім'я:
                                    <input type="text" name="firstName" placeholder="Введіть ім'я" value={formData.firstName} onChange={handleChange} required />
                                </label>
                                <label>
                                    Прізвище:
                                    <input type="text" name="lastName" placeholder="Введіть прізвище" value={formData.lastName} onChange={handleChange} required />
                                </label>
                                <label>
                                    Місто:
                                    <input type="text" name="city" placeholder="Введить населений пункт" value={formData.city} onChange={handleChange} required />
                                </label>
                                <label>
                                    Відділення:
                                    <input type="text" name="department" placeholder="Введіть відділення" value={formData.department} onChange={handleChange} required />
                                </label>
                                <label>
                                    Коментар:
                                    <textarea name="comment" placeholder="Коментар" value={formData.comment} onChange={handleChange} />
                                </label>
                            </div>

                            <button className="basket__pay" onClick={Order}>Підтвердити замовлення</button>
                        </div>
                    </div>}

                    {!products && <div className="basket__empty">
                        <div>
                            <p className="basket__empty-text">
                                Ваша корзина порожня
                            </p>
                            <Link className="basket__shop" to={'/shop'}>ЗА ПОКУПКАМИ</Link>
                        </div>
                    </div>}
                </div>
            </header>
            <Footer />
            
            {/* Модальне вікно підтвердження замовлення */}
            <Modal show={showModal} onClose={() => setShowModal(false)}>
             
            </Modal>
        </>
    );
}
