import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import "./contacts.scss";
import { Nav, Footer } from "../../components";

export const Contacts = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
    <Helmet>
                <title>Контакти | Взуттєва Мрія</title>
                <meta name="description" content="Купуйте жіноче взуття в інтернет-магазині Взуттєва Мрія за вигідними цінами ⭐ Найкращі пропозиції ✓ Повернення протягом 14 днів ⏩ Доставка по Україні ✔️ Оплата при отриманні ➤ В нас вигідніше!" />
                <meta name="keywords" content="обмін, повернення, доставка, взуття, інтернет-магазин, взуттєва мрія, магазин взуття, обувь, магазин обуви, кросівки, сліпони, мокасини, туфлі, кросовки, туфли, мокасины" />
                <meta name="robots" content="index, follow" />
                <link rel="icon" href="/favicon.ico" />
     </Helmet>
      <Nav />
      <main className="guarantee">
        <div className="container">
          <div className="guarantee__wrapper">
            <h3 className="guarantee__title title2">Контактна інформація</h3>
            <div className="guarantee__content">
              <p className="guarantee__text guarantee__center">Завжди на зв'язку</p>
              <div className="guarantee__contact">
                <div>
                  <p className="guarantee__social">Моб. + Вайбер</p>
                  <p className="guarantee__text">(067) 567 62 61</p>
                </div>
                <div>
                  <p className="guarantee__social">Моб.</p>
                  <p className="guarantee__text">(066) 543 18 16</p>
                </div>
                <div>
                  <p className="guarantee__social">E-mail</p>
                  <p className="guarantee__text">oleg10ok29@gmail.com</p>
                </div>
              </div>
              <h5 className="guarantee__subtitle">Режим роботи</h5>
              <p className="guarantee__text guarantee__center">Кожного дня з 8:00 до 22:00</p>
              <p className="guarantee__text guarantee__center">Оформлення заявок щодня</p>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
