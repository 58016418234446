import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import './shop.scss';
import header_img from '../../image/header-logo.png';
import { Nav, Footer, Pagination, Spiner } from '../../components/';

export const Shop = () => {
    const [size, setSize] = useState(null);
    const [type, setType] = useState(null);
    const [color, setColor] = useState(null);

    const [selectSort, setSelectSort] = useState("default"); 
    const [selectSize, setSelectSize] = useState("default"); 
    const [selectType, setSelectType] = useState("default");
    const [selectSeason, setSelectSeason] = useState("default"); 
    const [selectColor, setSelectColor] = useState("default"); 
    const [search, setSearch] = useState("");

    const [filterData, setFilterData] = useState(null);
    const [structuredData, setStructuredData] = useState(null);

    const { posts } = useSelector(state => state.post);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (posts.data) {
            const sizes = [];
            const types = [];
            const colors = [];
            posts.data.map(post => {
                post.sizes.map(size => {
                    if (!sizes.includes(size)) {
                        sizes.push(size);
                    }
                });
                if (!types.includes(post.type)) {
                    types.push(post.type);
                }
                if (!colors.includes(post.color)) {
                    colors.push(post.color);
                }
            });

            sizes.sort((a, b) => a - b);
            types.sort();
            colors.sort();
            setSize(sizes);
            setType(types);
            setColor(colors);
        }
    }, [posts.data]);

    useEffect(() => {
        applyFilter();
    }, [selectSort, selectSize, selectType, selectSeason, selectColor]); 

    const applyFilter = () => {
        let data = posts.data;
        if (selectSize !== "default") {
            data = data.filter(elem => elem.sizes.includes(selectSize));
        }
        if (selectType !== "default") {
            data = data.filter(elem => elem.type === selectType);
        }
        if (selectSeason !== "default") {
            data = data.filter(elem => elem.season === selectSeason);
        }
        if (selectColor !== "default") {
            data = data.filter(elem => elem.color === selectColor);
        }
        if (selectSort !== "default") {
            if (selectSort === 'popular') {
                data = [...data].sort((elem, nextelem) => nextelem.popular - elem.popular);
            }
            if (selectSort === 'unpopular') {
                data = [...data].sort((elem, nextelem) => elem.popular - nextelem.popular);
            }
            if (selectSort === 'rich') {
                data = [...data].sort((elem, nextelem) => nextelem.price - elem.price);
            }
            if (selectSort === 'poor') {
                data = [...data].sort((elem, nextelem) => elem.price - nextelem.price);
            }
        }

        if (data) {
            setFilterData(data);
            generateStructuredData(data);
        }
    };

    const generateStructuredData = (data) => {
        const structuredData = data.map(product => ({
            "@context": "https://schema.org",
            "@type": "Product",
            "name": product.title,
            "image": product.images.map(image => `https://api.shoedream.com.ua/uploads/${image}`),
            "description": product.description.replace(/<\/?[^>]+(>|$)/g, ""), // Очистка HTML тегів
            "brand": {
                "@type": "Brand",
                "name": product.brand
            },
            "sku": product.article,
            "material": product.material_top,
            "color": product.color,
            "priceCurrency": "UAH",
            "price": product.discount ? product.new_price : product.price,
            "offers": {
                "@type": "Offer",
                "url": `https://api.shoedream.com.ua/posts/${product.slug}`,
                "priceCurrency": "UAH",
                "price": product.discount ? product.new_price : product.price,
                "itemCondition": "https://schema.org/NewCondition",
                "availability": "https://schema.org/InStock"
            },
            "additionalType": "https://schema.org/Shoe",
            "size": product.sizes,
            "season": product.season,
            "countryOfOrigin": product.country,
            "discount": product.discount
        }));

        setStructuredData(structuredData);
    };

    const deleteFilter = () => {
        setFilterData(null);
        setStructuredData(null);
        setSelectSort("default"); 
        setSelectSize("default"); 
        setSelectType("default");
        setSelectSeason("default"); 
        setSelectColor("default");
        setSearch("");
    };

    const searchProduct = (value) => {
        if (value) { 
            let data = posts.data;
            data = [...data].filter(elem => elem.title.toLowerCase().includes(value.toLowerCase()));
    
            if (data) {
                setFilterData(data);
                generateStructuredData(data);
            }
        } else {
            setFilterData(null); 
            setStructuredData(null); 
        }
    };

    const handleSearchInputChange = (event) => {
        setSearch(event.target.value);
        searchProduct(event.target.value); 
    };

    const handleSearchButtonClick = () => {
        searchProduct(search); 
    };

    return (
        <>
            <Helmet>
                <title>Взуттєва Мрія | Інтернет-магазин Взуття — купити взуття недорого ▶️ Низькі Ціни в Україні</title>
                <meta name="description" content="Купуйте жіноче взуття в інтернет-магазині Взуттєва Мрія за вигідними цінами ⭐ Найкращі пропозиції ✓ Повернення протягом 14 днів ⏩ Доставка по Україні ✔️ Оплата при отриманні ➤ В нас вигідніше!" />
                <meta name="keywords" content="магазин, доставка, взуття, інтернет-магазин, взуттєва мрія, магазин взуття, обувь, магазин обуви, кросівки, сліпони, мокасини, туфлі, знижки, скидки, жіноче взуття, женская обувь" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Взуттєва Мрія | Інтернет-магазин Взуття — купити взуття недорого ▶️ Низькі Ціни в Україні" />
                <meta property="og:description" content="Взуттєва Мрія | Інтернет-магазин Взуття — купити взуття недорого ▶️ Низькі Ціни в Україні." />
                {structuredData && (
                    <script type="application/ld+json">
                        {JSON.stringify(structuredData)}
                    </script>
                )}
            </Helmet>
            <Nav />
            <header className="header">
                <div className="container">
                    <div className="header__wrapper">
                        <div className="header__content">
                            <h1 className="header__title title">Взуттєва мрія – нехай кожен крок буде особливим</h1>
                            <p className="header__text">Життя краще в комфортному взутті</p>
                        </div>
                        <img src={header_img} alt="header" className="header__img" loading="lazy"/>
                    </div>
                </div>
            </header>
            <div className="goods">
                <div className="container">
                    <div className="filter">
                        <div className="filter__top">
                            <select name="sort" className="filter__sort" onChange={(event) => setSelectSort(event.target.value)} value={selectSort}>
                                <option value="default" disable="false" hidden>Сортувати за</option>
                                <option value="popular">Спочатку популярні</option>
                                <option value="unpopular">Спочатку не популярні</option>
                                <option value="rich">Спочатку дорогі</option>
                                <option value="poor">Спочатку дешеві</option>
                            </select>
                            {size && <select name="size" className="filter__size" onChange={(event) => setSelectSize(event.target.value)} value={selectSize}>
                                <option value="default" disable="false" hidden>Розмір</option>
                                {
                                    size.map((elem, index) => (
                                        <option key={index} value={elem}>{elem}</option>
                                    ))
                                }
                            </select>}
                            {type && <select name="type" className='filter__type' onChange={(event) => setSelectType(event.target.value)} value={selectType}>
                                <option value="default" disable="false" hidden>Тип</option>
                                {
                                    type.map((elem, index) => (
                                        <option key={index} value={elem}>{elem}</option>
                                    ))
                                }
                            </select>}
                            <select name="season" className="filter__season" onChange={(event) => setSelectSeason(event.target.value)} value={selectSeason}>
                                <option value="default" disable="false" hidden>Сезон</option>
                                <option value="summer">Літо</option>
                                <option value="winter">Зима</option>
                                <option value="spring">Весна-осінь</option>
                            </select>
                            {color && <select name="color" className="filter__color" onChange={(event) => setSelectColor(event.target.value)} value={selectColor}>
                                <option value="default" disable="false" hidden>Колір</option>
                                {
                                    color.map((elem, index) => (
                                        <option key={index} value={elem}>{elem}</option>
                                    ))
                                }
                            </select>}
                        </div>
                        
                        <div className="filter__bottom">
                            <button className="filter__apply" onClick={applyFilter}>Застосувати</button>
                            <button className="filter__delete" onClick={deleteFilter}>Скинути</button>
                            <div className="filter__search">
                                <button className="filter__search-btn" onClick={handleSearchButtonClick}></button> 
                                <input type="text" className="filter__search-input" placeholder='Пошук' value={search} onChange={handleSearchInputChange} /> 
                            </div>
                        </div>
                    </div>
                    {posts.status === 'loading' && <Spiner />}
                    {posts.data && (
                        <Pagination items={filterData || posts.data} 
                            renderImage={(src) => (
                                <img src={src} alt="Product" loading="lazy" />
                            )}
                        />
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};
