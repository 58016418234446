import React from 'react';
import './Modal.scss'; 

const Modal = ({ show, onClose, children, productData, size }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay ordered_form acv" onClick={onClose}>
      <div className="modal-content ordered_form_block" onClick={(e) => e.stopPropagation()}>
        <p className="ordered_form_title">Товар доданий в кошик</p>
        <div className="flex jus">
          <img loading="lazy" src={`https://api.shoedream.com.ua${productData.images[0]}`} alt="" className="cart_in" />
          <div>
            <p className="tlc">{productData.title}</p>
            <div className="flex" style={{alignItems: 'center'}}> 
              <span style={{ fontWeight: 600, marginRight: '10px' }}>Розмір: <span id="rozmir">{size}</span></span>
              <span className="prac">{productData.discount ? productData.new_price : productData.price} грн</span>
            </div>
            {/* Використовуємо клас для стилізації артикула */}
            <p className="article">
              Артикул: {productData.article}
            </p>
          </div>
        </div>
        
        <div className="button-container"> {/* Додали контейнер для кнопок */}
          <a className="btn btn-default" href="https://shoedream.com.ua/basket">ОФОРМИТИ ЗАМОВЛЕННЯ</a>
          {/* Кнопка для закриття модального вікна */}
          <button className="btn gogo" onClick={onClose}>ПРОДОВЖИТИ ПОКУПКИ</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
