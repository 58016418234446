import React from 'react';
import './ConfirmationModal.scss';

const ConfirmationModal = ({ show, onClose }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <p className="modal-title">Ваше замовлення оформлено!</p>
        <p className="modal-message">
          Дякуємо за ваше замовлення. Незабаром з вами зв'яжеться наш оператор для підтвердження деталей.
        </p>
        <div className="button-container">
          <button className="btn gogo" onClick={onClose}>Повернутися до покупок</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
