import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";

// Оголошення асинхронних thunk
export const fetchNewPost = createAsyncThunk('post/fetchNewPost', async (params) => {
    const { data } = await axios.post('/posts', params);
    return data;
});

export const fetchAllPosts = createAsyncThunk('post/fetchAllPosts', async (params) => {
    const { data } = await axios.get('/posts', params);
    return data;
});

// Початковий стан
const initialState = {
    posts: {
        data: null,
        status: 'loading',
    }
};

// Оголошення slice
const postSlice = createSlice({
    name: 'post', // Змінено з 'auth' на 'post'
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllPosts.pending, (state) => {
                state.posts.status = 'loading';
                state.posts.data = null;
            })
            .addCase(fetchAllPosts.fulfilled, (state, action) => {
                state.posts.status = 'loaded';
                state.posts.data = action.payload;
            })
            .addCase(fetchAllPosts.rejected, (state) => {
                state.posts.status = 'error';
                state.posts.data = null;
            });
    }
});

// Експортування редуктора
export const postReducer = postSlice.reducer;
