import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import './product.scss';

export const Product = ({edit, data}) => {
    const [favourite, setFavourite] = useState(false);

    useEffect(() => {
        const favourites = JSON.parse(window.localStorage.getItem('favourite'));
        if (favourites) {
            const index = favourites.findIndex(fav => fav === data._id);
            if (index >= 0) {
                setFavourite(true);
            }
        }
    }, [data._id]);

    const addFavourite = () => {
        setFavourite(true);
        const favourites = JSON.parse(window.localStorage.getItem('favourite')) || [];
        favourites.push(data._id);
        window.localStorage.setItem('favourite', JSON.stringify(favourites));
    }

    const deleteFavourite = () => {
        const favourites = JSON.parse(window.localStorage.getItem('favourite'));
        const index = favourites.findIndex(fav => fav === data._id);
        favourites.splice(index, 1);
        window.localStorage.setItem('favourite', JSON.stringify(favourites));
        setFavourite(false);
    }

    const link = !edit ? `/shop/${data.slug}` : `/account/edit-product/${data._id}`;
    
    return ( 
        <div className="product">
    {data && (
        <>
            <div className="image">
                <Link to={link}>
                    <img
                        src={"https://api.shoedream.com.ua" + data.images[0]}
                        alt={data.title}
                        className="product__img"
                        loading="lazy"
                        onMouseOver={(e) =>
                            (e.currentTarget.src =
                                "https://api.shoedream.com.ua" + data.images[1])
                        }
                        onMouseOut={(e) =>
                            (e.currentTarget.src =
                                "https://api.shoedream.com.ua" + data.images[0])
                        }
                    />
                </Link>
            </div>
            <div className="name">
                <Link to={link}>
                    <span className="name_cat">{data.title}</span>
                </Link>
            </div>
            <div className="brand">
                <p>{data.brand}</p> {/* Назва бренду */}
            </div>
            <div className="catalog_options">
                <p>Доступні розміри:</p>
                <span>{data.sizes.join(', ')}</span>
            </div>
            <div className="price">
                <Link to={link}>
                    <span className="price-new">
                        {data.discount ? data.new_price : data.price} грн
                    </span>
                    {data.discount && (
                        <span className="price-old">
                            {data.price} грн
                        </span>
                    )}
                </Link>
            </div>
        </>
    )}
        </div>
    );
};
