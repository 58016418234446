import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";

// Оголошення асинхронних thunk
export const fetchUserData = createAsyncThunk('auth/fetchUserData', async (params) => {
    const { data } = await axios.post('/auth/login', params);
    return data;
});

export const fetchAuthMe = createAsyncThunk('auth/fetchAuthMe', async (params) => {
    const { data } = await axios.get('/auth/me', params);
    return data;
});

export const fetchRegister = createAsyncThunk('auth/fetchRegister', async (params) => {
    const { data } = await axios.post('/auth/register', params);
    return data;
});

export const fetchUpdate = createAsyncThunk('auth/fetchUpdate', async (params) => {
    const { data } = await axios.patch('/auth/update', params);
    return data;
});

// Початковий стан
const initialState = {
    auth: {
        data: null,
        status: 'loading',  
    },
    success: {
        data: null,
        status: 'loading',  
    }
};

// Оголошення slice
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.auth.data = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserData.pending, (state) => {
                state.auth.status = 'loading';
                state.auth.data = null;
            })
            .addCase(fetchUserData.fulfilled, (state, action) => {
                state.auth.status = 'loaded';
                state.auth.data = action.payload;
            })
            .addCase(fetchUserData.rejected, (state) => {
                state.auth.status = 'error';
                state.auth.data = null;
            })
            .addCase(fetchAuthMe.pending, (state) => {
                state.auth.status = 'loading';
                state.auth.data = null;
            })
            .addCase(fetchAuthMe.fulfilled, (state, action) => {
                state.auth.status = 'loaded';
                state.auth.data = action.payload;
            })
            .addCase(fetchAuthMe.rejected, (state) => {
                state.auth.status = 'error';
                state.auth.data = null;
            })
            .addCase(fetchRegister.pending, (state) => {
                state.auth.status = 'loading';
                state.auth.data = null;
            })
            .addCase(fetchRegister.fulfilled, (state, action) => {
                state.auth.status = 'loaded';
                state.auth.data = action.payload;
            })
            .addCase(fetchRegister.rejected, (state) => {
                state.auth.status = 'error';
                state.auth.data = null;
            })
            .addCase(fetchUpdate.pending, (state) => {
                state.success.status = 'loading';
                state.success.data = null;
            })
            .addCase(fetchUpdate.fulfilled, (state, action) => {
                state.success.status = 'loaded';
                state.success.data = action.payload;
            })
            .addCase(fetchUpdate.rejected, (state) => {
                state.success.status = 'error';
                state.success.data = null;
            });
    }
});

// Вибірки стану
export const selectIsAuth = (state) => Boolean(state.auth.auth.data);

// Експортування редуктора та дій
export const authReducer = authSlice.reducer;
export const { logout } = authSlice.actions;
