import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import './home.scss';
import header_img from '../../image/header-logo.png';
import { Nav, Footer, Product, ProductSlider} from '../../components/';

export const Home = () => {
    const [count, setCount] = useState(10);

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    useEffect(() => {
        const handleWindowResize = () => {
            let width = window.innerWidth;
            if(width < 675){
                setCount(10)
            }
            if(width > 675 && width < 990){
                setCount(9)
            }
            if(width > 990){
                setCount(8)
            }
            if(width > 1200){
                setCount(10)
            }
        };
    
        window.addEventListener('resize', handleWindowResize);
        

    }, [])

    const{ posts } = useSelector(state => state.post)
    const popular = posts.data;
    const sale = posts.data && posts.data.filter((post) => {
        if(post.discount){
            return post;
        }
    }).slice(0,8);

    
    return (
        <>
            <Helmet>
                <title>Взуттєва Мрія | Інтернет-магазин Взуття — купити взуття недорого ▶️ Низькі Ціни в Україні</title>
                <meta name="description" content="Купуйте жіноче взуття в інтернет-магазині Взуттєва Мрія за вигідними цінами ⭐ Найкращі пропозиції ✓ Повернення протягом 14 днів ⏩ Доставка по Україні ✔️ Оплата при отриманні ➤ В нас вигідніше!" />
                <meta name="keywords" content="магазин, доставка, взуття, інтернет-магазин, взуттєва мрія, магазин взуття, обувь, магазин обуви, кросівки, сліпони, мокасини, туфлі, знижки, скидки, жіноче взуття, женская обувь" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Взуттєва Мрія | Інтернет-магазин Взуття — купити взуття недорого ▶️ Низькі Ціни в Україні" />
                <meta property="og:description" content="Взуттєва Мрія | Інтернет-магазин Взуття — купити взуття недорого ▶️ Низькі Ціни в Україні." />
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <Nav />
            <header className="header">
                <div className="container">
                    <div className="header__wrapper">
                        <div className="header__content">
                            <h1 className="header__title title">Взуттєва мрія – нехай кожен крок буде особливим</h1>
                            <p className="header__text">Життя краще в комфортному взутті</p>
                            <Link className="header__shop" to={'/shop'}>ЗА ПОКУПКАМИ</Link>
                        </div>
                        <img src={header_img} alt="header" className="header__img"/>
                    </div>
                </div>
            </header>
            <div className="popular">
                <div className="container">
                    <h3 className="popular__title title2">Популярні</h3>
                    <div className="popular__products">
                        { popular && [...popular].sort((post, nextpost) => nextpost.popular - post.popular).slice(0, count).map((post, index) => (
                            <Product key={index} edit={false} data={post}/>
                        ))} 
                    </div>  
                </div>
            </div>
            <div className="sale">
                <div className="container">
                    <h3 className="sale__title title2">Розпродаж</h3>
                    <div className="sale__wrapper">
                        {
                            sale && <ProductSlider data={sale}/>
                        }
                        
                    </div>
                </div>
            </div>

            
            <Footer />
        </>
    );
};